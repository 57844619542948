import './App.css';
import Home from './pages/home';
import Login from './pages/login';
import { Switch, Route, Redirect } from 'react-router-dom';
import 'antd/dist/antd.css';
import Cookies from 'js-cookie';
function App(props) {
  const Private = ({ component, ...data }) => {
    const currentUser = Cookies.get('accessToken');
    return currentUser ? (
      <Route component={component} {...data} />
    ) : (
      <Redirect to="/login" />
    );
  };
  return (
    <div className="app">
      <Switch>
        <Route component={Login} path="/login" {...props} />
        <Private component={Home} path="/" {...props} />
      </Switch>
    </div>
  );
}

export default App;
