import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Loader from "../components/Loader";
import UserTable from "../components/UserTable";
import { db } from "../firebase";
import { Modal, Avatar, Button } from "antd";
import { Header } from "antd/lib/layout/layout";
function SupplierFeature({users,supplierFeature}) {
	const [load, setLoad] = useState(false);
	let history = useHistory();
	const [view, setView] = useState(false);
	var [userData, setUserData] = useState([]);
	
	const getQuestions = (data, id) => {
		history.push({
			pathname: `/questions/${data.userId}`,
			state: { data },
		});
	};
	const View = (value) => {
		let data = {};
		console.log(value);
		users.map((user) => {
			if (user.uid == value) {
				data = user;
			}
		});
		setUserData(data);
		setView(!view);
	};
	const columns = [
		{
			title: "Email",
			dataIndex: "email",
			key: "email",
		},
		{
			title: "Payment Status",
			dataIndex: "status",
			align: "center",
			key: "status",
		},
		{
			align: "center",
			title: "Action",
			key: "acion",
			render: (record) => (
				<div className='btn-container'>
				<Button onClick={() => View(record.userId)}>View User</Button>
					<Button onClick={() => getQuestions(record)}>View Question</Button>
				</div>
			),
		},
	];
	return (
		<div>
			{load ? (
				<Loader />
			) : (
				<>
					<UserTable
						data={supplierFeature}
						columns={columns}
						dropdown={false}
					/>
					<Modal
						visible={view}
						onOk={() => setView(!view)}
						onCancel={() => setView(!view)}>
						{userData?.UserType === 1 ? (
							<>
								<Header className='header'>User Info</Header>
								<strong className='modal-head col'>
									<Avatar>{userData?.FirstName}</Avatar>
								</strong>
								<div className='modal-body'>
									<span>
										Name: {userData?.FirstName} {userData?.LastName}{" "}
									</span>
									<span>User Type: {userData?.user_type}</span>
									<span>City: {userData?.City} </span>
									<span>Country: {userData?.Role} </span>
									<span>Role: {userData?.Country} </span>
									<strong>Partner Info:</strong>
									<span>
										Parnter Name: {userData?.partnerName}{" "}
										{userData?.partnerLastName}
									</span>
									<span>Role: {userData?.partnerRole} </span>
									<span>State: {userData?.state} </span>
								</div>
							</>
						) : (
							<>
								<Header className='header'>Supplier Info</Header>
								<strong className='modal-head col'>
									<Avatar>{userData?.first_name}</Avatar>
								</strong>
								<div className='modal-body'>
									<span>
										Name: {userData?.first_name} {userData?.last_name}{" "}
									</span>
									<span>Business Name: {userData?.business_name} </span>
									<span>User Type: {userData.user_type}</span>
									<span>PostCode: {userData?.post_code} </span>
									<span>Phone Number: {userData?.phone_number} </span>
								</div>
							</>
						)}
					</Modal>
				</>
			)}
		</div>
	);
}

export default SupplierFeature;
