import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { BASE_URL } from '../api/proxy';
import Dashboard from '../components/dashboard/dashboard';
import Footer from '../components/footer/footer';
import MenuBar from '../components/menubar/menuBar';
import Navbar from '../components/navbar/navbar';
import { db } from '../firebase';
import '../App.css';
import { LoadingOutlined } from '@ant-design/icons';
import Loader from '../components/Loader';
export default function Home() {
  const [users, setUsers] = useState([]);
  var userList = [];

  const [userDetails, setUserDetails] = useState([]); //data from api
  var userData = []; //data from api

  const [allUsers, setAllUsers] = useState([]); //mapped data from firebase
  var userDetail = [];
  const [load, setLoad] = useState(true);
  const [list, setList] = useState([]);
  var listings = [];
  const [noOfData,setNoOfData]=useState({
    listingNumber:0,
    userNumber:0,
    supplierNumber:0,
    socialNumber:0
  })

  const [socialPops, setSocialPops] = useState([]); //data from api
  var socialPopsData = []; //data from api
  const [supplierFeature, setSupplierFeature] = useState([]); //data from api
  var supplierFeatureData = []; //data from api

  useEffect(async () => {
    await axios
      .get(`${BASE_URL}/ListUser`, {
        headers: {
          accessToken: document.cookie.split(':')[1],
        },
      })
      .then((res) => {
        res.data.map((newData) => {
          let user = {
            email: newData.email,
            uid: newData.uid,
          };
          userData.push(user);
        });
        setUserDetails(userData);
      })
      .catch((err) => {
        console.log(err);
      });
    // FIREBASE COLLECTION DATA
    await db
      .collection('UserDetails')
      .get()
      .then((querySnapshot) => {
        querySnapshot.docs.map((doc) => {
          let user = doc.data();
          user.uid = doc.id;
          userDetail.push(user);
        });
        setAllUsers(userDetail);
      });
    await fetchAllData();

    await db
      .collection('list')
      .get()
      .then((querySnapshot) => {
        querySnapshot.docs.map((doc) => {
          listings.push(doc.data());
        });
        setList(listings);
      });
      await db.collection("SocialPops").get().then(querySnapshot=>{
        querySnapshot.docs.map(doc=>{
          socialPopsData.push(doc.data());
        })
      setSocialPops(socialPopsData);
      })
      await db.collection("SupplierOnShowForm").get().then(querySnapshot=>{
        querySnapshot.docs.map(doc=>{
          supplierFeatureData.push(doc.data());
        })
      setSupplierFeature(supplierFeatureData);
      })
      // const noOfSocials = db.collection("SocialPops").get().then(snapShot=>snapShot.docs.length)
      setNoOfData({
        listingNumber:list.length,
        userNumber:userData.length,
        socialNumber:socialPops.length,
        supplierNumber:supplierFeature.length,
        })

    setLoad(false);
  }, [load]);
  const fetchAllData = () => {
    let userInfo = {};
    userDetails.map((data, id) => {
      allUsers.map((anotherData) => {
        if (data.uid == anotherData.uid) {
          userInfo = {
            ...anotherData,
            user_type: anotherData.UserType == 1 ? 'User' : 'Supplier',
            email: data.email,
          };
          userList.push(userInfo);
        }
      });
    });
    setUsers(userList);
  };
  return (
    <div className='home'>
      <Navbar></Navbar>
      <MenuBar></MenuBar>
       {!load ? (
        <Dashboard data={users} list={list} listofData={noOfData} supplierList={supplierFeature} socialList={socialPops}></Dashboard>
      ) : (
       <Loader/>
      )} 

      <Footer></Footer>
    </div>
  );
}
