import React from 'react';

export default function Footer() {
  return (
    
      <footer className="main-footer">
        <strong>Copyright © 2021 </strong>
        All rights reserved.
      </footer>

  );
}
