import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react'
import {useHistory} from "react-router-dom";
import "../index.css";
function GoBack() {
    let history = useHistory();
  return (<Button
  className='goback'
  onClick={() =>
      history.goBack()
  }>
  <ArrowLeftOutlined />
  Go Back To Page
</Button>)
}

export default GoBack