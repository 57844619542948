import React from 'react';
import { withRouter } from 'react-router-dom';
import { Button } from 'antd';
import UserTable from '../components/UserTable';

function Listing(props) {
  const data = props.location.state.listing;
  const columns = [
    {
      title: 'Name',
      dataIndex: 'business_name',
      key:"business_name",
      width:150,
    },       
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
      width:150,
    },
    {
      title: 'Payment Status',
      dataIndex: 'PaymentStatus',
      render: PaymentStatus=><li className='list'>{PaymentStatus}</li>,
      width:150,
      align:"center",
    },
    {
      title: 'Action',
      width:150,
      align:"center",
      render: (PaymentStatus,record) => (
        <Button className="fail" onClick={()=>console.log(record)}>
          {record.PaymentStatus=="paid"?"Cancel Subscription":"Subscribe"}
          </Button>
        )}
    ,
  ];
  return (
    <div>
      <UserTable columns={columns} data={data} type={0} dropdown={false}/>
    </div>
  );
}

export default withRouter(Listing);
