import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, message } from 'antd';
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';
export default function Navbar() {
  let history=useHistory();
  const [search,setSearch]=useState("");
  const searchPage =(e)=>{
    e.preventDefault();
    history.push({
      pathname:search,
    })
    setSearch("");
  }
  const logOut = async() => {
    Cookies.remove('accessToken');
    await message.info({
      content:"Logging out...",
      style:{
        marginTop:"5vh",
      }
    });
    history.push({
      pathname:"/login"
    })
  };
  
  return (
      <div className="main-header navbar navbar-expand navbar-white navbar-light">
        {/* Left navbar links */}
        <ul className="navbar-nav">
          <li className="nav-item">
            <a
              className="nav-link"
              data-widget="pushmenu"
              href="#"
              role="button">
              <i className="fas fa-bars" />
            </a>
          </li>

        </ul>
        
        {/* Right navbar links */}
        <ul className="navbar-nav ml-auto">
          {/* Navbar Search */}
          <li className="nav-item">
            <a
              className="nav-link"
              data-widget="navbar-search"
              href="#"
              role="button">
              <i className="fas fa-search" />
            </a>
            <div className="navbar-search-block">
              <form className="form-inline" onSubmit={searchPage}>
                <div className="input-group input-group-sm">
                  <input
                    className="form-control form-control-navbar"
                    type="search"
                    value={search}
                    placeholder="Search"
                    onChange={(e)=>setSearch(e.target.value)}
                    aria-label="Search"
                  />
                  
                  <div className="input-group-append">
                    <Link to={search} className="btn btn-navbar" type="submit">
                      <i className="fas fa-search" />
                    </Link>
                    <button
                      className="btn btn-navbar"
                      type="button"
                      data-widget="navbar-search">
                      <i className="fas fa-times" />
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </li>
          {/* Messages Dropdown Menu */}
         
          {/* Notifications Dropdown Menu */}
          <li className="nav-item dropdown">
            <a className="nav-link" data-toggle="dropdown" href="#">
              <i className="far fa-bell" />
              <span className="badge badge-warning navbar-badge">15</span>
            </a>
            <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
              <span className="dropdown-item dropdown-header">
                15 Notifications
              </span>
              <div className="dropdown-divider" />
              <a href="#" className="dropdown-item">
                <i className="fas fa-envelope mr-2" /> 4 new messages
                <span className="float-right text-muted text-sm">3 mins</span>
              </a>
              <div className="dropdown-divider" />
              <a href="#" className="dropdown-item">
                <i className="fas fa-users mr-2" /> 8 friend requests
                <span className="float-right text-muted text-sm">12 hours</span>
              </a>
              <div className="dropdown-divider" />
              <a href="#" className="dropdown-item">
                <i className="fas fa-file mr-2" /> 3 new reports
                <span className="float-right text-muted text-sm">2 days</span>
              </a>
              <div className="dropdown-divider" />
              <a href="#" className="dropdown-item dropdown-footer">
                See All Notifications
              </a>
            </div>
          </li>
          <li>
            <Button className="primary" onClick={logOut}>
              Log Out
            </Button>
          </li>
        </ul>
      </div>
  );
}
