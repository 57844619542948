import React from "react";
import { Link } from "react-router-dom";

export default function MenuBar() {
	return (
		<div>
			<aside className='main-sidebar sidebar-dark-primary elevation-4'>
				{/* Brand Logo */}
				<Link className='brand-link' to='/dashboard'>
					{/* <img src="dist/img/AdminLTELogo.png" alt="AdminLTE Logo" class="brand-image img-circle elevation-3" style="opacity: .8"> */}
					<span className='brand-text font-weight-light'>Best Wedding</span>
				</Link>
				{/* Sidebar */}
				<div className='sidebar'>
					{/* Sidebar user panel (optional) */}
					<div className='user-panel mt-3 pb-3 mb-3 d-flex'>
						<div className='image'>
							<img
								src='dist/img/user2-160x160.jpg'
								className='img-circle elevation-2'
								alt='User Image'
							/>
						</div>
						<div className='info'>
							<a href='#' className='d-block'>
								Admin
							</a>
						</div>
					</div>
					{/* SidebarSearch Form */}
					<div className='form-inline'>
						<div className='input-group' data-widget='sidebar-search'>
							<input
								className='form-control form-control-sidebar'
								type='search'
								placeholder='Search'
								aria-label='Search'
							/>
							<div className='input-group-append'>
								<button className='btn btn-sidebar'>
									<i className='fas fa-search fa-fw' />
								</button>
							</div>
						</div>
					</div>
					{/* Sidebar Menu */}
					<nav className='mt-2'>
						<ul
							className='nav nav-pills nav-sidebar flex-column '
							data-widget='treeview'
							role='menu'
							data-accordion='false'>
							<Link to='/dashboard' className='nav-link'>
								<i class='nav-icon fas fa-tachometer-alt'></i>
								<p>Dashboard</p>
							</Link>
							<Link to='/users' className='nav-link'>
								<i className='nav-icon fas fa-users' />
								<p>Users</p>
							</Link>{" "}
							<Link to='/social-popups' className='nav-link'>
								<i className='nav-icon fas fa-users' />
								<p>Social Popups</p>
							</Link>{" "}
							<Link to='/supplier-feature' className='nav-link'>
								<i className='nav-icon fas fa-users' />
								<p>Supplier Feature</p>
							</Link>
						</ul>
					</nav>
					{/* /.sidebar-menu */}
				</div>
				{/* /.sidebar */}
			</aside>
		</div>
	);
}
