import { useEffect, useState, React, useRef } from 'react';
import { notification, Button, Table, Modal, Popconfirm, message } from 'antd';

import { Header } from 'antd/lib/layout/layout';
import Avatar from 'antd/lib/avatar/avatar';

import { withRouter, useHistory } from 'react-router-dom';
import { reset } from './../api/axios';
import UserTable from '../components/UserTable';
function User({ data, list }) {
  const [view, setView] = useState(false);
  const formRef = useRef();
  const [edit, setEdit] = useState(false);
  var [userData, setUserData] = useState([]);
  let history = useHistory();

  const View = (value) => {
    setView(!view);
 setUserData(value);
  };
  const Edit = (value, type) => {
    setEdit(!edit);
    setUserData(value);
  };
  const Delete = (data) => {
    message.success({
      content: `user ${data} deleted successfully`,
      duration: 1,
      className: 'custom-class',
      style: {
        marginTop: '5vh',
      },
    });
  };
  const getListings = (uid) => {
    let listObj = {
      userId: '',
      listArray: [],
      key: Number,
    };
    list.map((listing, id) => {
      if (listing.userId == uid) {
        listObj.key = id;
        listObj.userId = listing.userId;
        listObj.listArray.push(listing);
      }
    });
    if (listObj.listArray.length > 0) {
      history.push({
        pathname: `/listings`,
        state: { listing: listObj.listArray },
      });
    } else {
      message.error({
        content: `No Listing found for this user`,
        style: {
          marginTop: '5vh',
        },
      });
    }
  };
  const resetPwd = (email) => {
    reset
      .post('/passwordReset', { email: email })
      .then((res) => {
        if (res.status == 200) {
          message.success({
            content: `Password Reset Email Sent!`,
            duration: 4,
            style: {
              marginTop: '5vh',
            },
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const sumbmitForm = (e) => {
    e.preventDefault();
    let formArray = formRef.current;
    let data = {
      ...userData,
    };
  };
  const styles = {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: '10px',
  };
  //   Columns for the table
  const columns = [
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      align:"left",
      width:150,
      
    },
    {
      title: 'User ID',
      dataIndex: 'uid',
      key: 'uid',
      align:"center",
      width:300,

    },
    {
      title: 'User Type',
      dataIndex: 'user_type',
      key: 'user_type',
      align:"center",
      width:150,

    },
    {
      title: 'Actions',
      align:"center",
      width:250,
      render: (text, record, index) => {
        return (
          <div style={styles} className="actions">
            <span onClick={(e) => View(record)}>
    
              <Button className='primary'>View</Button>
            </span>
            <span
              style={{ color: 'blue' }}
              onClick={(e) => Edit(record, 'edit')}>
         
              <Button className='primary'>Edit</Button>
            </span>
            {record.UserType != 1 ? (
              <span onClick={() => getListings(record.uid)}>
         
                <Button className='primary'>Listings</Button>
              </span>
            ) : (
              ''
            )}
            <Popconfirm
              cancelText="No"
              okText="Yes"
              title="Are you sure you want to delete the user?"
              onConfirm={() => Delete(record.uid)}>
              
              <span style={{ color: 'red', fontSize: '14px' }}>
              <Button className='delete'>Delete</Button>

              </span>
            </Popconfirm>
            <span onClick={(e) => resetPwd(record.email)}>
     
              <Button className='primary'>Password Reset</Button>
            </span>
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <UserTable data={data} columns={columns} type={1} dropdown={true}/>
      <Modal
        visible={view}
        onOk={() => setView(!view)}
        onCancel={() => setView(!view)}>
        {userData?.UserType === 1 ? (
          <>
            <Header className="header">User Info</Header>
            <strong className="modal-head col">
              <Avatar>{userData?.FirstName}</Avatar>
            </strong>
            <div className="modal-body">
              <span>
                Name: {userData?.FirstName} {userData?.LastName}{' '}
              </span>
              <span>
                User Type: {userData?.user_type}
              </span>
              <span>City: {userData?.City} </span>
              <span>Country: {userData?.Role} </span>
              <span>Role: {userData?.Country} </span>
              <strong>Partner Info:</strong>
              <span>
                Parnter Name: {userData?.partnerName}{' '}
                {userData?.partnerLastName}
              </span>
              <span>Role: {userData?.partnerRole} </span>
              <span>State: {userData?.state} </span>
            </div>
          </>
        ) : (
          <>
            <Header className="header">Supplier Info</Header>
            <strong className="modal-head col">
              <Avatar>{userData?.first_name}</Avatar>
            </strong>
            <div className="modal-body">
              <span>
                Name: {userData?.first_name} {userData?.last_name}{' '}
              </span>
              <span>Business Name: {userData?.business_name} </span>
              <span>
                User Type: {userData.user_type}
              </span>
              <span>PostCode: {userData?.post_code} </span>
              <span>Phone Number: {userData?.phone_number} </span>
            </div>
          </>
        )}
      </Modal>
      {/* Edit Modal */}
      <Modal
        onOk={() => setEdit(!edit)}
        onCancel={() => setEdit(!edit)}
        visible={edit}>
        <form
          onSubmit={sumbmitForm}
          ref={formRef}
          style={{ display: 'flex', flexDirection: 'column' }}>
          <label>UserType: {userData.user_type}</label>
          <label>Email:</label>
          <input
            value={userData.email}
            onChange
            placeholder="Enter your email"
          />
          {userData?.UserType === 1 ? (
            <>
              <label>First Name:</label>
              <input value={userData.FirstName} />
              <label>Last Name:</label>
              <input value={userData.LastName} />
              <label>City:</label>
              <input value={userData?.City} />
              <label>Country: </label>
              <input value={userData?.Country} />

              <label>Role: </label>
              <input value={userData?.Role} />
              <br />
              <strong style={{ color: 'gray' }}>Partner Info:</strong>
              <label>Parnter FirstName:</label>
              <input value={userData?.partnerName} />
              <label>Parnter LastName:</label>
              <input value={userData?.partnerLastName} />
              <span>Role: </span>
              <input value={userData?.partnerRole} />

              <span>State: </span>
              <input value={userData?.state} />
            </>
          ) : (
            <>
              <label>First Name: </label>
              <input value={userData.first_name} />
              <label>Last Name: </label>
              <input value={userData?.last_name} />
              <label>Business Name</label>
              <input value={userData?.business_name} />
              <label>PostCode: </label>
              <input value={userData?.post_code} />
              <label>Phone Number: </label>
              <input value={userData?.phone_number} />
            </>
          )}

          <button>Submit</button>
        </form>
      </Modal>
      <Popconfirm></Popconfirm>
    </div>
  );
}

export default withRouter(User);
