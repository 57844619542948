import { getAuth } from 'firebase/auth';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
const firebaseConfig = {
  apiKey: 'AIzaSyCeHwNqhXaKPETKue283qJlNJjq4uO1O8I',
  authDomain: 'bestweddingappweb.firebaseapp.com',
  projectId: 'bestweddingappweb',
  storageBucket: 'bestweddingappweb.appspot.com',
  messagingSenderId: '869195410378',
  appId: '1:869195410378:web:7c7379fd2e39e01bce7d33',
  measurementId: 'G-0HTN24W0TR',
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();
const auth = firebase.auth();
// const db = firebaseApp.firestore();
// export { auth, db };
export { firebaseApp, auth, db };
