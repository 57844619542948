import React, { useState } from "react";
import { useHistory, withRouter } from "react-router-dom";
import "../assets/styles/Questions.css";
import GoBack from "../components/GoBack";

const questions = [
	{
		id: 1,
		question: `Tell us your name and company`,
	},
	{
		id: 2,
		question: `How did you start out? How long you have been in the business for and what do you know about weddings?`,
	},
	{
		id: 3,
		question: `Does your brand have a specific style?`,
	},
	{
		id: 4,
		question: `How many people do you have on your team and who should we expect on the day? If no one, do you have a back up plan?	`,
	},
	{
		id: 5,
		question: `Tell us a bit about your process when booking a wedding from start to finish? What's included and what's not?`,
	},
	{
		id: 6,
		question: `What info do you require from a couple to best understand their vision and accurately quote on jobs?`,
	},
	{
		id: 7,
		question: `Is there anywhere that couples can see your work?`,
	},
	{
		id: 8,
		question: `Tell us a bit about your fee structure?`,
	},
	{
		id: 9,
		question: `Do you work on locations and do your prices include travel fees?`,
	},
	{
		id: 10,
		question: `What does your calendar look like this year and how far in advance are you booked?`,
	},
	{
		id: 11,
		question: `Please list up to 5 vendors you love working with? (If they are also on the Best Wedding App we we create direct links to
		their profiles)`,
	},
	{
		id: 12,
		question: `How many weddings have you done over the years?`,
	},
	{
		id: 13,
		question: `FAOS: What can couples expect from you? `,
	},
	{
		id: 14,
		question:`Got any hot tips for couples when booking a business like yours?`,
	},
	{
		id: 15,
		question: `What's was the coolest, craziest or your most memorable wedding to work en?`,
	},
	{
		id: 16,
		question: `Tell us a bit about the face behind the brand. What do you get up to on a day off and where would we tind you?`,
	},	{
		id: 17,
		question: `Where do you find your personal flare and inspiration for the business?`,
	},	{
		id: 18,
		question: `
		What do you love most about your job and working on the adding industry?`,
	},	{
		id: 19,
		question: `Finally, Tell us why you're awesome? What sets your business apart from the rest.
		Please add link and pin lif applicable) to downicad your wedding images.`,
	},	{
		id: 20,
		question: `Enter your email address`},

	
];

function Questions(props) {
const [answers, setAnswers]=useState(props.location.state.data);
const getAnswers = ()=>{
	console.log(props.location.state.data)
}
return( <div className="question-list">
	<GoBack/>
		{questions.map(question=>(
			<ol key={question.id}><strong style={{fontSize:"12px"}}>Q no.{question.id}</strong>{" "}{question.question}<br/><strong>Answer: </strong>{answers[`question${question.id}`]}</ol>
		))}
	</div>);
}

export default withRouter(Questions);
