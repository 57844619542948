import React, { useEffect, useState } from 'react';
import './dashboard.css';
import { Route, Switch, BrowserRouter, Redirect } from 'react-router-dom';
import User from './../../pages/User';
import AdminDashboard from '../../pages/AdminDashboard';
import Listing from '../../pages/Listing';
import SocialPops from '../../pages/SocialPops';
import SupplierFeature from '../../pages/SupplierFeature';
import Questions from '../../pages/Questions';
import PageNotFound from '../../pages/PageNotFound';

 function Dashboard({ data, list,listofData,supplierList,socialList }) {
  return (
    <div className="content-wrapper">
        <h4 style={{textAlign:"center", fontWeight:"700", paddingTop:"10px"}}>{window.location.pathname.split("/")[1].toUpperCase().replace("-"," ").replace("-"," ")}</h4>

      <Switch>
        <Route exact render={()=><AdminDashboard data={listofData}/>} path="/dashboard" />
        <Route
          exact
          render={() => <User data={data} list={list} />}
          path="/users"
        />
        <Route exact path="/listings" component={Listing} />
        <Route exact path="/social-popups" render={()=><SocialPops users={data} socialPops={socialList}/>}  />
        <Route exact path="/supplier-feature" render={()=><SupplierFeature users={data} supplierFeature={supplierList}/>}/>
        <Route exact path="/questions/:id" component={Questions} />
        <Route exact path="/Page-Not-Found" component={PageNotFound} />
        <Redirect to="/Page-Not-Found" />
      </Switch>
    </div>
  );
}
export default Dashboard;