import { Button } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'
import GoBack from '../components/GoBack'
import tbw from "../assets/images/TBW.svg"
function PageNotFound() {
  return (
    <div className='page-not-found col'>
        <img src={tbw}/>
        <h2>Oops! Seems like the page you are looking for<br/> doesn't exist</h2>
        <Link to="/dashboard">
        <Button>Go Back To Home</Button>
        </Link>
    </div>
  )
}

export default PageNotFound