import React, { useState, useEffect } from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../firebase';
import { Route } from 'react-router-dom';
import Dashboard from '../components/dashboard/dashboard';
import { withRouter } from 'react-router-dom';
import Cookies from 'js-cookie';
import { message } from 'antd';
import { useHistory } from 'react-router-dom';

function Login(props) {
  const [cookie, setCookie] = useState(Cookies.get('accessToken'));
  const [password, setpwd] = useState('');
  const [email, setEmail] = useState('');
  let history = useHistory();
  const signIn = (e) => {
    e.preventDefault();
    signInWithEmailAndPassword(auth, email, password)
      .then( async(user) => {
        var expires = new Date();
        expires.setTime(expires.getTime() + 1 * 3600 * 1000);
        var time = expires;
        Cookies.set('accessToken', user.user.accessToken, {
          expires: time,
        });
        await message.success({
          content:"Authenticated, Logging In..."
        });
        history.push({
          pathname:"/dashboard"
        });
        setEmail('');
        setpwd('');
        

      })
      .catch((err) => {
        message.error(`Login Failed!, Please enter valid email/password`)
      });
  };

  return (
    <div className="hold-transition login-page">
      <div className="login-box">
        <div className="login-logo">
          <b>Best</b>Wedding
        </div>
        {/* /.login-logo */}
        <div className="card">
          <div className="card-body login-card-body">
            <p className="login-box-msg">Sign in to continue</p>
            <form method="post" noValidate>
              <div className="input-group mb-3">
                <input
                  type="email"
                  placeholder="Email"
                  v-model="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-envelope" />
                  </div>
                </div>
              </div>
              <div className="adjust-validation-err-margin">
                <p v-for="error of v$.email.$errors"></p>
              </div>
              <div className="input-group mb-3">
                <input
                  type="password"
                  placeholder="Password"
                  v-model="password"
                  value={password}
                  onChange={(e) => setpwd(e.target.value)}
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-lock" />
                  </div>
                </div>
              </div>
              <div className="adjust-validation-err-margin">
                <p v-for="error of v$.password.$errors"></p>
              </div>
              <div className="row ml-0">
                {/* /.col */}
                <div className="col-12 mt-3">
                  <button
                    type="submit"
                    className="btn btn-primary btn-block"
                    onClick={signIn}>
                    Sign In
                  </button>
                </div>
                {/* /.col */}
              </div>
            </form>
            {/* /.social-auth-links */}
          </div>
          {/* /.login-box-body */}
        </div>
      </div>
    </div>
  );
}
export default withRouter(Login);
