import { ArrowLeftOutlined, InfoCircleFilled, WindowsOutlined } from '@ant-design/icons';
import { Button, Input, Select, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import "../assets/styles/UserTable.css"
function UserTable({data, columns,type, dropdown}) {
  const[load, setLoad]=useState(true)
  useEffect(()=>{
        setTimeout(()=>{
            setLoad(false)
        },1000);
  })

  
    let history = useHistory();
    const [filteredData, setFilteredData]=useState(data); 
    const [select, setSelect]=useState(0);
    const filterUser=(search)=>{
       let searchFilter = [];
           filteredData.filter(info=>{
               
               if(info.email.toLowerCase().includes(search.toLowerCase())){
                   searchFilter.push(info);
               }else if(search==""){
                   searchFilter.push(info)
               }
           })
if(search==""){
    setFilteredData(data);
    setSelect(0);
}else{
    setFilteredData(searchFilter);
}
    }
    const selectFilter=(id)=>{
        setSelect(id);
        let searchFilter=[];
        data.filter(user=>{
            if(user.UserType==id){
                searchFilter.push(user);
            }else if(id==0){
                searchFilter.push(user);
            }
        })
        setFilteredData(searchFilter);
    }

    const listTypes = [
        
            [
                {
                    id:1,
                    name:"Paid"
                },{
                    id:2,
                    name:"Unpaid"
                }
            ]
        ,
        [
            {
                id:1,
                name:"User"
            },{
                id:2,
                name:"Supplier"
            },
        ]
    ]   
    //   const selectFilter=(id)=>{
    //     console.log(id);
    //     let searchFilter=[];
    //     data.filter(list=>{
    //         if(type==0){
    //             console.log("list")
    //             if(list.PaymentStatus==id){
    //                 searchFilter.push(list);
    //             }
    //         }else if(type==1){
    //             if(list.userType==id){
    //                 searchFilter.push(list);
    //             }
    //         }

    //     })
    //     setFilteredData(searchFilter);

    // }
  return (
    <div className='user-table'>
        <div className='top-btns'>
        <Button
        onClick={() =>
            history.goBack()
        }>
        <ArrowLeftOutlined />
        Go Back To Page
      </Button>
        <div className="search" style={{display:"flex"}}>
            <Input onChange={(e)=>filterUser(e.target.value)} placeholder={type==0?"Search List..":"Search Email"}/> 
            {dropdown&&( <Select onSelect={selectFilter} value={select} defaultValue={select} defaultOpen={select}>
                <Select.Option value={0}>All</Select.Option>
                {listTypes[type].map(option=>
                    (
                        <Select.Option key={option.id}>{option.name}</Select.Option>
                    )
                )}
            </Select>)}
                
        </div>
        </div>
      <Table dataSource={load?null:filteredData} columns={columns} className="table" scroll={{y:600}} loading={load}/>
    </div>
  )
}

export default UserTable
