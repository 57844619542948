import { LoadingOutlined } from '@ant-design/icons'
import React from 'react'

function Loader() {
  return (

    <span className='loader'>
        <div className='overlay'></div>
          <LoadingOutlined className="custom-spinner" />
        </span>
  )
}

export default Loader